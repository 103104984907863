import React, {useState} from 'react';
import DynamicForm from '../../components/DynamicForm'; // Import your existing DynanicForm component
import productBImageUrl from '../../assets/images/product_b.jpeg'
import tree87opaqueSymbol from "../../assets/images/tree87opaqueSymbol.png";
import WideImageSlice from "../../components/WideImageSlice";
import {WORDS} from '../../dictionary/words';
import './overlayMessage.css';
import {useNavigate} from 'react-router-dom';

const MapProductPage = ({ productIndex }) => {
  const navigate = useNavigate();
  const [showOverlay, setShowOverlay] = useState(false); // State to toggle overlay

  const index = productIndex ?? 'A';
  const productName = `PRODUCT_${index}`;
  window.scrollTo(0, 0);
  const baseURL = process.env.REACT_APP_BASE_URL;
  /**
   * product index is a name, like a or b or c
   */
  const DESCRIPTION = WORDS.PRODUCTS[productName].DESCRIPTION;
  const ITEMS = WORDS.PRODUCTS[productName].ITEMS;
  const overlayMessage = WORDS.PRODUCTS[productName].OVERLAY_SUCCESS_MESSAGE;

  const onFormSubmit = (data) => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };

    console.log('DynamicForm submit', { data, baseURL, requestOptions });
    fetch(`${baseURL}/v1/reports/sendform`, requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log('cert check', data);
        setShowOverlay(true);
      });
  }

  const navigateOnSuccess = () => {
    navigate('/')
  }

  return (
    <div className="min-h-screen">
      {showOverlay && (
        <div className="overlay">
          <div className="overlay-content">
            <p className="m-10">{overlayMessage}</p>
            <button
              onClick={navigateOnSuccess}
              type="submit"
              className="relative group w-full border-4 border-tree87Green
        bg-white py-2 px-4 rounded-full overflow-hidden hover:opacity-90
        transition duration-800 hover:text-tree87Green-800"
            >
              <div>
                בסדר גמור
                <div
                  className="absolute bg-tree87Green inset-0 bg-gradient-to-r from-transparent via-white to-transparent opacity-0 group-hover:opacity-60 group-hover:animate-sparkle"></div>
              </div>
            </button>

          </div>
        </div>
      )}
      <WideImageSlice imageUrl={productBImageUrl} part={'top'}/>
      <div className="flex flex-col-reverse md:flex-row px-6 py-10 md:py-20 justify-center items-center">
        <div className="w-full md:w-1/2 mb-10 m-4 md:m-2">
          <DynamicForm isShowPhone={true}
                       isShowEmail={true}
                       productName={productName}
                       onFormSubmit={onFormSubmit}
          />
        </div>
        <div className="md:w-1/2 md:pl-10 relative" dir={'rtl'}>
          {/*<img src={tree87opaqueSymbol} alt="tree87 logo" className="scale-50 hidden md:block"/>*/}
          <div style={{
            backgroundImage: `url(${tree87opaqueSymbol})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
          }}>
            <h2 className="text-lg md:text-2lg font-bold font-lia-shamaim relative z-10">
              {DESCRIPTION}
            </h2>
            <div className="mt-6 text-lg leading-relaxed">
              <ul>
                {
                  ITEMS.map((message, index) => (
                    <li className="font-polin-light text-right" key={message + index}>- {message}</li>
                  ))
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapProductPage;
